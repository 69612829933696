import { useState,useEffect } from 'react';
import ContainerHouse from '../images/Luxury Caravans.jpg';
import Alert from 'react-bootstrap/Alert';



const Contact = () => {

  const initialValues = 
    {
      firstname: '',
      lastname: '',
      email: '',
      phno: '',
      message: ''
    }
  

  const [formData, setFormData] = useState(initialValues);

  const [isFormSubmitted, setFormSubmitted] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });

    // Clear the error message when the user starts typing
    setErrors({
      ...errors,
      [e.target.name]: ''
    });
  };

  const validateForm = () => {
    const newErrors = {};

    // Example validation rules (you can customize these as needed)
    if (!formData.firstname || !formData.firstname.trim()) {
      newErrors.firstname = 'First Name is required';
    }

    if (!formData.lastname || !formData.lastname.trim()) {
      newErrors.lastname = 'Last Name is required';
    }

    if (!formData.email || !formData.email.trim()) {
      newErrors.email = 'Email is required';
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = 'Invalid email format';
    }

    if (!formData.phno || !formData.phno.trim()) {
      newErrors.phno = 'Phone Number is required';
    } else if (!/^\d{10}$/.test(formData.phno)) {
      newErrors.phno = 'Invalid phone number format';
    }

    if (!formData.message || !formData.message.trim()) {
      newErrors.message = 'Message is required';
    }

    setErrors(newErrors);

    // Return true if there are no errors, false otherwise
    return Object.keys(newErrors).length === 0;
  };


  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      console.log(formData)
      setFormData(initialValues);
      setFormSubmitted(true);


      // Send the form data to your server or API endpoint using fetch or axios
      fetch('https://srv442800.hstgr.cloud:2002/starlink/contact-us', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      })
        .then(response => response.json())
        .then(data => {
          // Handle the response from the server if needed
          console.log(data);
        })
        .catch(error => {
          console.error('Error:', error);
        });
    }
  };
  const handleAlertClose = () => {
    // Reset the form submission status when the alert is closed
    setFormSubmitted(false);
    setShowAlert(false);
  };
  useEffect(() => {
    if (isFormSubmitted) {
      setShowAlert(true);
  
      const timeoutId = setTimeout(() => {
        handleAlertClose();
      }, 3000);
  
      // Clear the timeout when the component unmounts or when the alert is manually closed
      return () => clearTimeout(timeoutId);
    }
  }, [isFormSubmitted]);
  return (
    <>
      <div className="text-center">
        <div className="overlay-2"></div>
        <h1>Contact Us</h1>
      </div>

      <div className="container" style={{ display: "flex", alignItems: "center" }}>

        <div className='home-about-details' id='contents'>
          <div className='line'>
            <div id='line'></div>
            <h5>Contact Us</h5>
          </div>
          <p>
            Explore the boundless possibilities with Starlink Trading - FZCO, and let us be your trusted partner in transforming visions into reality. Contact us today for all your container, motor home, and container office needs.
          </p>
          <div className='contacts-del'>
            <h6><a href="mailto:contact@starlinkexp.com"><span>Mail:</span>contact@starlinkexp.com</a></h6>
            <h6><span>Address:</span>Dubai Silicon Oasis- Dubai- UAE</h6>
          </div>


        </div>
        <div className="contact-image">
          <img src={ContainerHouse} alt="" />
        </div>
      </div>

      <div className="container-md" style={{ marginBottom: "20px" }}>
        <div className='line'>
          <div id='line'></div>
          <h5>Contact Us</h5>
        </div>
      </div>

      <div className="container-md">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col">
              <label htmlFor="">First Name</label>
              <input
                type="text"
                id="firstname"
                name="firstname"
                placeholder="Enter your Name"
                value={formData.firstname}
                onChange={handleChange}
              />
              <span>{errors.firstname}</span>
            </div>
            <div className="col">
              <label htmlFor="">Last Name</label>
              <input
                type="text"
                id="lastname"
                name="lastname"
                placeholder="Enter your Name"
                value={formData.lastname}
                onChange={handleChange}
              />
              <span>{errors.lastname}</span>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label htmlFor="">Email</label>

              <input
                type="email"
                placeholder='Enter Your Email'
                name='email'
                value={formData.email}
                onChange={handleChange}
              />
              <span>{errors.email}</span>
            </div>
            <div className="col">
              <label htmlFor="">Phone Number</label>

              <input
                type="phone"
                placeholder='Enter your Number'
                name='phno'
                value={formData.phno}
                onChange={handleChange}
              />
              <span>{errors.phno}</span>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label htmlFor="">Message</label>
              <textarea
                name="message"
                id=""
                cols="20"
                rows="5"
                value={formData.message}
                onChange={handleChange}>

              </textarea>
              <span>{errors.message}</span>
            </div>
          </div>
          <div className="enquire-button">
            <button onSubmit={handleSubmit}>Send</button>
          </div>

        </form>
      </div>
      <Alert variant="success" show={showAlert} onClose={handleAlertClose} dismissible style={{position:"fixed", top:"50px", left:"50%", transform:"translate(-50%,-50%",zIndex:"9000",padding:"50px"}}>
  Form submitted successfully!
</Alert>
    </>
  );
}

export default Contact;
