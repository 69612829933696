import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Accordian from './accordian';
import ContainerHouse from '../images/container-tudo-sobre-wilson-scaled.jpeg';
import Slash from '../images/slash.png';
import cor from '../images/maxresdefault.jpg';

const About = () => {
  const Testimonials = [
    {
      Name: "Sagitra Nath",
      para:"I recently had the pleasure of working with Starlink, and I must say, their service exceeded all expectations. From the moment I reached out to inquire about purchasing a container, their team was incredibly responsive and helpful. They guided me through the entire process, from selecting the right size and type of container for my needs to arranging prompt delivery to my site      "
    },
    {
      Name: "Amelia Moore ",
      para:"Working with starlink was an absolute pleasure! As a small business owner, I needed a cost-effective yet professional office space solution, and their container offices fit the bill perfectly. From the initial consultation to the final delivery and setup, the team at starlink was attentive, efficient, and professional. They listened to my needs and provided expert guidance on the best container office configuration for my business "
    },
    {
      Name: "Sophia Davis",
      para:"Choosing starlink for my caravan purchase was one of the best decisions I've made. The staff at Caravan Haven took the time to understand my needs and preferences, guiding me through their extensive selection to find the perfect caravan for my family. Not only did they offer a wide range of high-quality caravans, but their expertise and attention to detail ensured that I made an informed decision.       "
    },
    {
      Name: "John Anderson",
      para:"As a mobile service provider, we needed a reliable and functional caravan to serve as our on-the-go headquarters, and starlink delivered beyond our expectations. Right from the start, the team at starlink took the time to understand our unique business requirements. They provided expert guidance on selecting the perfect caravan model to meet our needs, considering factors such as size, layout, and customization options.      "
    },
    {
      Name: "Samuel Hernandez      ",
      para:"As a fledgling company, we needed a workspace that could adapt to our evolving needs while also reflecting our innovative spirit. Thanks to starlink, we were able to hit the ground running and focus on growing our business without having to worry about the logistics of setting up a traditional office space.       "
    },
    {
      Name: "James Smith",
      para:"From our very first meeting, the team at Container Creations demonstrated their expertise and commitment to understanding our unique needs. They guided us through the entire process, from selecting the right containers for our project. The quality of the containers provided by Container Creations was exceptional, that gave us confidence in their longevity.      "
    },
  ]
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024, // Adjust based on your design needs
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 700, // Adjust based on your design needs
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="text-center">
        <div className="overlay-2"></div>
        <h1>About Us</h1>
      </div>

      <div className="container" style={{ display: "flex", alignItems: "center" }}>
        <div className="container-image">
          <img src={ContainerHouse} alt="" />
        </div>
        <div className='home-about-details' id='contents'>
          <div className='line'>
            <div id='line'></div>
            <h5>About Our Company</h5>
          </div>
          <h2>
            Star Link Trading
          </h2>
          <p>
            Starlinks Trading - FZCO takes pride in being a distinguished provider of versatile and innovative solutions, specializing in the sale of various containers, motor homes, and container offices. Established with a commitment to excellence, our company has emerged as a prominent player in the industry, offering a diverse range of high-quality products to meet the distinctive needs of our valued clients.
          </p>

          <h5>Our Vision</h5>
          <p>At Starlinks Trading - FZCO, our vision is to redefine the boundaries of container-related solutions. We aspire to become the preferred destination for individuals and businesses seeking reliable, functional, and customized container solutions. Our ultimate goal is to play a pivotal role in shaping efficient and sustainable spaces for the future.</p>
          <h5>Our Mission</h5>
          <p>Our mission is to deliver top-tier container, motor home, and container office solutions that surpass the expectations of our clients. We are dedicated to providing innovative, cost-effective, and environmentally responsible products that contribute to the success and growth of our customers.</p>
        </div>
      </div>

      <div className="text-center" id='about-caption'>
        <div className="overlay-2"></div>
        <p>Discover freedom on the road and versatility in every space with our premium range of caravans, motorhomes, containers, and container offices.</p>
      </div>

      <div className="container" >
        <div className='home-about-details' id='contents'>
          <h2>
            What Sets Us Apart
          </h2>
          <p>
            Distinctively exploit optimal alignments for intuitive business applications through revolutionary catalysts for chang the Seamlessly optimal optimal alignments for intuitive.
          </p>

          <h5>Quality Assurance</h5>
          <p>
            Distinctively exploit optimal alignments for intuitive business applications through revolutionary catalysts for chang the Seamlessly optimal optimal alignments for intuitive.
          </p>
          <h5>Commitment to Sustainability</h5>
          <p>
            Distinctively exploit optimal alignments for intuitive business applications through revolutionary catalysts for chang the Seamlessly optimal optimal alignments for intuitive.
          </p>
          <Accordian />

        </div>
        <div className='sets-us-apart'>
          <img src={ContainerHouse} alt="" />
          <img src={cor} alt="" />
        </div>


      </div>

      <div className="best-deal">
        <h2>Lets Hear What People Says</h2>
      </div>

      <div className="container-md">
        <Slider {...settings}>
          {
            Testimonials.map((data, index) => {
              return <div className='testimonial-card'>

                <div className='design'>
                  <img src={Slash} alt="" />
                </div>
                <h5>{data.Name}</h5>
                <p>{data.para}</p>
              </div>
            })
          }
        </Slider>

      </div>

    </>
  );
}

export default About;
