import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Homecontainer from '../images/carosel container.png';
import cor1 from '../images/istockphoto-492948138-612x612.jpg';
import cor2 from '../images/maxresdefault.jpg'

function DarkVariantExample() {
  return (
    <Carousel data-bs-theme="dark" className='moving-carousel'>
      <Carousel.Item className='carousel-item'>
        <div className="overlay"></div>
        <img
          className="d-block w-100"
          src={Homecontainer}
          alt="First slide"
        />
        <Carousel.Caption className='carousel-caption'>
          <h2><b>BEST CONTAINER SELLERS</b> </h2>
          <p>
          We at Starlink redefine what quality in container solutions means. Whether you're in need of storage, shipping, or custom modifications starlink is here for you

          </p>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item className='carousel-item'>
        <div className="overlay"></div>
        <img
          className="d-block w-100"
          src={cor2}
          alt="Second slide"
        />
        <Carousel.Caption>
          <h2><b>BEST SELLING MOTORS HOME</b></h2>
          <p>
          Whether going on a cross-country journey or enjoying a weekend getaway, starlink ensures a memorable and comfortable travel experience, making it the preferred choice for countless travelers worldwide.
          </p>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item className='carousel-item'>
        <div className="overlay"></div>
        <img
          className="d-block w-100"
          src={cor1}
          alt="Third slide"
        />
        <Carousel.Caption>
          <h2><b>BEST CARAVANS AVAILABLE</b></h2>
          <p>
          Designed with travelers in mind, starlink caravans combines spacious interiors with clever storage solutions, allowing adventurers to bring along all the comforts of home on their journeys.          </p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default DarkVariantExample;
